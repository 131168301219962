import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { UserInterface } from "../interfaces/user.interface";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private router: Router, private httpClient: HttpClient) {}

  user?: UserInterface;

  loginUser(username: string, password: string): Observable<UserInterface> {
    return this.httpClient
      .post<UserInterface>(environment.baseAuthURL + "portal.login", {
        userName: username,
        password,
      })
      .pipe(
        map(response => {
          this.user = response;
          localStorage.setItem("user", JSON.stringify(response));
          return response;
        })
      );
  }

  recoverAccountByEmail(email: string): Observable<any> {
    return this.httpClient.post<UserInterface>(
      environment.baseAuthURL + "emailRecovery",
      {
        email,
      }
    );
  }

  getAuthenticatedUser$(): Observable<UserInterface | undefined> {
    if (this.user) {
      return of(this.user);
    }
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      return of(JSON.parse(storedUser));
    }
    return of(undefined);
  }

  logout() {
    this.user = undefined;
    localStorage.removeItem("user");
    this.router.navigateByUrl("/login").then();
  }
}
