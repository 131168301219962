import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LoggingService } from "../_shared/services/logging.service";

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private loggingService: LoggingService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    let status: string;

    // extend server response observable with logging
    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        response => {
          status = response instanceof HttpResponse ? "succeeded" : "";
          if (this.loggingService.showHttpResponsesInConsole()) {
            if (status) {
              console.log(
                `Logging Intercepted Original Response: ${request.urlWithParams} `,
                response
              );
            }
          }
          return response;
        },
        // Operation failed; error is an HttpErrorResponse
        error => {
          status = "failed";
          console.log(
            `Logging Intercepted Original Error: ${request.url} `,
            error
          );
        }
      ),
      finalize(() => {
        const timeElapsed = Date.now() - started;
        const httpStatusMessage = `${request.method} ${request.urlWithParams} ${status} in ${timeElapsed} ms.`;
        if (this.loggingService.showHttpRequestStatusInConsole()) {
          console.log(httpStatusMessage);
        }
      })
    );
  }
}
