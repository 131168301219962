import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
  MatSnackBarModule,
} from "@angular/material/snack-bar";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { UiSpinnerModule } from "./_shared/ui-spinner/ui-spinner.module";
import { httpInterceptorProviders } from "./interceptors";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatNativeDateModule,
    MatSnackBarModule,
    UiSpinnerModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000, // Set the duration to 3 seconds
        horizontalPosition: "center",
        verticalPosition: "top",
      } as MatSnackBarConfig,
    },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
