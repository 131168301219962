import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  catchError,
  EMPTY,
  from,
  Observable,
  throwError,
  take,
  of,
} from "rxjs";
import { Injectable } from "@angular/core";
import { map, mergeMap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmDialogComponent } from "../_shared/confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private location: Location,
    private snackBar: MatSnackBar
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const error = {
          ...err.error,
          status: err.status,
        };
        const dialogData = {
          title: error.statusMessageTitle ? error.statusMessageTitle : "Error",
          message: error.statusMessage
            ? error.statusMessage
            : "Something went wrong, please try again.",
          showCancel: error.statusMessageFalseButtonLabel ? true : false,
          trueButtonLabel: error.statusMessageTrueButtonLabel
            ? error.statusMessageTrueButtonLabel
            : "Ok",
          falseButtonLabel: error.statusMessageFalseButtonLabel
            ? error.statusMessageFalseButtonLabel
            : "Cancel",
        };
        const caseZeroMessage = error?.statusMessage
          ? error?.statusMessage
          : "No internet connection. Please try again later.";
        const caseFourHundredMessage = error?.statusMessage
          ? error?.statusMessage
          : "Bad request. Please correct the request and try again.";
        const caseFiveHundredThreeMessage = error?.statusMessage
          ? error?.statusMessage
          : "Server is currently down. Please try again later.";

        const caseFiveHundredFourMessage = error?.statusMessage
          ? error?.statusMessage
          : "Server timed out. Please try again later.";
        switch (err.status) {
          case 0:
            this.snackBar.open(caseZeroMessage);
            return EMPTY;
          case 400:
            return this.dialog
              .open(ConfirmDialogComponent, {
                maxWidth: "500px",
                data: dialogData,
              })
              .afterClosed()
              .pipe(
                take(1),
                mergeMap(result => {
                  return throwError(result.error);
                })
              );
          case 401:
          case 403:
            this.snackBar.open(dialogData.message);
            this.dialog.closeAll();
            return from(this.router.navigate(["/logout"])).pipe(
              map(() => error)
            );
          case 404:
            this.snackBar.open(dialogData.message);
            return throwError(error);
          case 409:
          case 412:
          case 423:
            return this.dialog
              .open(ConfirmDialogComponent, {
                maxWidth: "500px",
                data: dialogData,
              })
              .afterClosed()
              .pipe(
                take(1),
                mergeMap(result => {
                  if (result && !result.error) {
                    this.location.back();
                  }
                  if (result.error) {
                    return throwError(result.error);
                  } else {
                    return of(
                      new HttpResponse({
                        ...err,
                        url: err.url ? err.url : undefined,
                        body: result.data,
                      })
                    );
                  }
                })
              );
          case 500:
          case 502:
          case 503:
            this.snackBar.open(caseFiveHundredThreeMessage);
            this.dialog.closeAll();
            return from(this.router.navigate(["/logout"])).pipe(
              map(() => error)
            );
          case 504:
            this.snackBar.open(caseFiveHundredFourMessage);
            return throwError(error);
          default:
            this.snackBar.open(dialogData.message);
            return throwError(error);
        }
      })
    );
  }
}
