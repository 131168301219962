import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { catchError, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { UiSpinnerService } from "../_shared/ui-spinner/ui-spinner.service";

@Injectable()
export class UiSpinnerInterceptor implements HttpInterceptor {
  constructor(private spinnerService: UiSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinnerService.show();
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        this.spinnerService.hide();
        return Promise.reject(err);
      }),
      finalize(() => {
        this.spinnerService.hide();
      })
    );
  }
}
