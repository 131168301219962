import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

import { LoggingService } from "../_shared/services/logging.service";
import { mergeMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthenticationService } from "../_shared/services/authentication.service";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private loggingService: LoggingService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authenticationService.getAuthenticatedUser$().pipe(
      mergeMap(user => {
        if (this.loggingService.showOriginalHttpRequestsInConsole()) {
          console.log(
            `Auth Intercepted Original Request of type ${request.method}: ${request.urlWithParams}`,
            request
          );
        }

        // set user information if available
        const token = user?.jwt ? user.jwt : null;
        const authID = user?.authID ? user.authID : null;

        const copiedRequest = request.clone({
          setHeaders: {
            "x-mvconnect": "1884",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            authID: "" + authID,
            appName: environment.appName,
            appVersion: environment.appVersion,
          },
        });

        if (this.loggingService.showClonedHttpRequestsInConsole()) {
          console.log(
            `Auth Intercepted Cloned Request of type ${copiedRequest.method}: ${request.urlWithParams}`,
            copiedRequest
          );
        }

        return next.handle(copiedRequest);
      })
    );
  }
}
