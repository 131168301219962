<h1 mat-dialog-title class="gnl-dialog-title">{{ title }}</h1>
<div mat-dialog-content class="gnl-content">
  {{ message }}
</div>
<div mat-dialog-actions class="gnl-buttons" align="center">
  <button
    *ngIf="showCancel"
    mat-raised-button
    color="warn"
    type="button"
    (click)="cancel()">
    {{ falseButtonLabel ? falseButtonLabel : "Cancel" }}
  </button>
  <button mat-raised-button color="accent" type="button" (click)="confirmed()">
    {{ trueButtonLabel ? trueButtonLabel : "Ok" }}
  </button>
</div>
