import { Injectable } from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SpinnerComponent } from "./ui-spinner.component";

@Injectable()
export class UiSpinnerService {
  private spinnerTopRef: OverlayRef;
  private isRequesting = false;

  constructor(private overlay: Overlay, private snackBar: MatSnackBar) {
    this.spinnerTopRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: "gnl-backdrop",
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  show(): void {
    if (!this.isRequesting) {
      this.spinnerTopRef.attach(new ComponentPortal(SpinnerComponent));
      this.isRequesting = true;
    }
  }

  hide(): void {
    if (this.isRequesting) {
      this.spinnerTopRef.detach();
      this.isRequesting = false;
    }
  }

  showError(message: string): void {
    this.snackBar.open(message, "Error", {
      duration: 7000,
      panelClass: ["error-snackbar"],
      horizontalPosition: "end",
      verticalPosition: "top",
    });
  }
}
