/*
CUSTOMER SALES PORTAL APP PROD
 */
import { EnvironmentInterface } from "../app/_shared/interfaces/environment.interface";

export const environment: EnvironmentInterface = {
  appName: "Customer_Sales_Portal",
  appVersion: "3.0.3",
  environmentReference: "Prod",
  production: true,
  serviceWorker: false,
  baseURL: "https://webservices.ganahl.com/api/request/",
  baseDocURL: "https://webservices.ganahl.com/api/docserver/display/",
  baseAuthURL: "https://webservices.ganahl.com/api/l/request/",
  imageURL: null,
};
