import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "gnl-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
  title = "";
  message = "";
  trueButtonLabel = "";
  falseButtonLabel = "";
  showCancel = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      trueButtonLabel: string | undefined;
      falseButtonLabel: string | undefined;
      showCancel: boolean;
    },
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    dialogRef.disableClose = true;
    dialogRef.disableClose = true;
    this.message = data.message
      ? data.message
      : "Are you sure you want to proceed?";
    this.title = data.title ? data.title : "Please Confirm";
    this.trueButtonLabel = data?.trueButtonLabel ? data.trueButtonLabel : "Ok";
    this.falseButtonLabel = data?.falseButtonLabel
      ? data.falseButtonLabel
      : "Cancel";
    this.showCancel = data.showCancel ? data.showCancel : false;
  }

  confirmed() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }
}
