import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { UiSpinnerService } from "./ui-spinner.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SpinnerComponent } from "./ui-spinner.component";

@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    CommonModule,
    OverlayModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
  ],
  providers: [UiSpinnerService],
})
export class UiSpinnerModule {}
