import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  constructor() {}

  showHttpRequestStatusInConsole() {
    if (!environment.production) {
      return true;
    }
    return false;
  }

  showOriginalHttpRequestsInConsole() {
    if (!environment.production) {
      return false;
    }
    return false;
  }

  showClonedHttpRequestsInConsole() {
    if (!environment.production) {
      return true;
    }
    return false;
  }

  showHttpResponsesInConsole() {
    if (!environment.production) {
      return true;
    }
    return false;
  }
}
