import { inject } from "@angular/core";
import { AuthenticationService } from "../_shared/services/authentication.service";
import { InvoicesService } from "../_shared/services/invoices.service";

export const signOutGuard = () => {
  const authService = inject(AuthenticationService);
  const invoiceService = inject(InvoicesService);
  invoiceService.clearInvoiceCache();
  authService.logout();
  return true;
};
