import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { environment } from "../environments/environment";

@Component({
  selector: "gnl-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Customer Portal";

  constructor(
    private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.renderDataCapScript();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 130);
      }
    });
  }

  renderDataCapScript() {
    const script = this.renderer.createElement("script");
    const env = environment.environmentReference;
    script.type = "text/javascript";
    script.src = "https://token-cert.dcap.com/v1/client";
    script.integrity =
      "sha384-pGPEfcs4XG4Nr1nPt0g++l1Fmyh9recOkKsaNUiuJMD6YEoJU3cninw/wk4U4516";
    script.setAttribute("crossorigin", "anonymous");
    if (env === "Prod") {
      script.type = "text/javascript";
      script.src = "https://token.dcap.com/v1/client";
      script.integrity =
        "sha384-bMesiInsWmGw22eOnCdlIKWXScg7CyCz7pweyX54JavPO0G3j5qLIpd4CpOzDZhz";
      script.setAttribute("crossorigin", "anonymous");
    }
    this.renderer.appendChild(this.document.head, script);
  }
}
