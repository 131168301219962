import { inject } from "@angular/core";

import { Router } from "@angular/router";
import { AuthenticationService } from "../_shared/services/authentication.service";
import { take } from "rxjs";
import { map } from "rxjs/operators";

export const authGuard = () => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  return authService?.getAuthenticatedUser$().pipe(
    take(1),
    map(user => {
      if (user) {
        return true;
      }
      // Redirect to the login page
      return router.navigateByUrl("/login");
    })
  );
};
