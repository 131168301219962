import { Injectable } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { map, tap } from "rxjs/operators";
import {
  InvoiceDetailInterface,
  InvoiceInterface,
} from "../interfaces/invoices.interface";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class InvoicesService {
  lastFetchedInvoiceUnpaid: DateTime | undefined;
  unpaidInvoices: InvoiceInterface[] | undefined;
  totalUnpaidInvoices: number | undefined;

  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient
  ) {}

  clearInvoiceCache() {
    this.lastFetchedInvoiceUnpaid = undefined;
    this.unpaidInvoices = undefined;
    this.totalUnpaidInvoices = undefined;
  }

  getUnpaidJobInvoices(jobNumber: string): Observable<{
    invoices: InvoiceInterface[];
    totalInvoices: number;
  }> {
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.get<{
          invoices: InvoiceInterface[];
          totalInvoiceCount: number;
        }>(
          environment.baseURL +
            `invoices/${user.customerNO}/${user.custHomeYard}`,
          {
            params: {
              type: "unpaid",
              jobNO: jobNumber,
            },
          }
        );
      }),
      map(response => {
        const invoices = response.invoices.map(res => res);
        // {
        //   const balance = parseFloat(
        //     res.invoiceAmtDue.replace("$", "").replace(/,/g, "")
        //   );
        //   return {
        //     ...res,
        //     amountDue: balance,
        //   };
        // });
        return {
          invoices,
          totalInvoices: response.totalInvoiceCount,
        };
      })
    );
  }

  getInvoiceById(invoiceNo: string): Observable<InvoiceDetailInterface> {
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.get<{ invoice: Array<InvoiceDetailInterface> }>(
          environment.baseURL +
            `invoice/${user.customerNO}/${user.custHomeYard}`,
          {
            params: {
              invoice: invoiceNo,
            },
          }
        );
      }),
      map(res => {
        const invoices = res.invoice;
        return invoices[0];
      })
    );
  }

  checkInvoiceBalanceById(invoiceNo: string): Observable<boolean> {
    return this.getUnpaidInvoices().pipe(
      map(response => {
        const invoice = response.invoices.find(
          inv => inv.invoiceNO === invoiceNo
        );
        if (invoice) {
          const balance = Number(invoice.invoiceAmtDue);
          if (balance > 0) {
            return true;
          }
        }
        return false;
      })
    );
  }

  getUnpaidInvoices(): Observable<{
    invoices: InvoiceInterface[];
    totalInvoices: number;
  }> {
    if (this.lastFetchedInvoiceUnpaid?.isValid) {
      const duration = this.lastFetchedInvoiceUnpaid
        .diffNow("minutes")
        .as("minutes");
      if (duration < 30 && this.unpaidInvoices && this.totalUnpaidInvoices) {
        console.log("RETURN FROM CACHE UNPAID INVOICES");
        return of({
          invoices: this.unpaidInvoices,
          totalInvoices: this.totalUnpaidInvoices,
        });
      }
    }
    return this.fetchUnpaidInvoices();
  }

  private fetchUnpaidInvoices(): Observable<{
    invoices: InvoiceInterface[];
    totalInvoices: number;
  }> {
    console.log("RETURN FETCH UNPAID INVOICES FROM API");
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.get<{
          invoices: InvoiceInterface[];
          totalInvoiceCount: number;
        }>(
          environment.baseURL +
            `invoices/${user.customerNO}/${user.custHomeYard}`,
          {
            params: {
              type: "unpaid",
            },
          }
        );
      }),
      map(response => {
        const invoices = response.invoices.map(res => res);
        // {
        //   const balance = parseFloat(
        //     res.invoiceAmtDue.replace("$", "").replace(/,/g, "")
        //   );
        //   return {
        //     ...res,
        //     amountDue: balance,
        //   };
        // });
        return {
          invoices,
          totalInvoices: response.totalInvoiceCount,
        };
      }),
      tap(response => {
        this.unpaidInvoices = response.invoices;
        this.lastFetchedInvoiceUnpaid = DateTime.now();
        this.totalUnpaidInvoices = response.totalInvoices;
      })
    );
  }

  getUnpaidInvoicesPreview(): Observable<{
    invoices: InvoiceInterface[];
    totalInvoices: number;
  }> {
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.get<{
          invoices: InvoiceInterface[];
          totalInvoiceCount: number;
        }>(
          environment.baseURL +
            `invoices/${user.customerNO}/${user.custHomeYard}`,
          {
            params: {
              type: "unpaid_preview",
            },
          }
        );
      }),
      map(response => {
        const invoices = response.invoices.map(res => res);
        // {
        //   const balance = parseFloat(
        //     res.invoiceAmtDue.replace("$", "").replace(/,/g, "")
        //   );
        //   return {
        //     ...res,
        //     amountDue: balance,
        //   };
        // });
        return {
          invoices,
          totalInvoices: response.totalInvoiceCount,
        };
      })
    );
  }

  getInvoiceHistory(monthCount: number): Observable<{
    invoices: InvoiceInterface[];
    totalInvoices: number;
  }> {
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.get<{
          invoices: InvoiceInterface[];
          totalInvoiceCount: number;
        }>(
          environment.baseURL +
            `invoices/${user.customerNO}/${user.custHomeYard}`,
          {
            params: {
              type: "history",
              monthCount,
            },
          }
        );
      }),
      map(response => {
        const invoices = response.invoices.map(res => res);
        // {
        //   const balance = parseFloat(
        //     res.invoiceAmtDue.replace("$", "").replace(/,/g, "")
        //   );
        //   return {
        //     ...res,
        //     amountDue: balance,
        //   };
        // });
        return {
          invoices,
          totalInvoices: response.totalInvoiceCount,
        };
      })
    );
  }

  getInvoiceHistoryMonthly(monthCount: number): Observable<InvoiceInterface[]> {
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.get<{
          invoices: InvoiceInterface[];
          totalInvoiceCount: number;
        }>(
          environment.baseURL +
            `invoices/${user.customerNO}/${user.custHomeYard}`,
          {
            params: {
              type: "history",
              monthCount,
            },
          }
        );
      }),
      map(res => res.invoices),
      map(
        response => response.map(res => res)
        // {
        //   const balance = parseFloat(
        //     res.invoiceAmtDue.replace("$", "").replace(/,/g, "")
        //   );
        //   return {
        //     ...res,
        //     amountDue: balance,
        //   };
        // })
      )
    );
  }

  getInvoiceHistoryByJobNo(jobNumber: string): Observable<{
    invoices: InvoiceInterface[];
    totalInvoices: number;
  }> {
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.get<{
          invoices: InvoiceInterface[];
          totalInvoiceCount: number;
        }>(
          environment.baseURL +
            `invoices/${user.customerNO}/${user.custHomeYard}`,
          {
            params: {
              type: "history",
              jobNO: jobNumber,
            },
          }
        );
      }),
      map(response => {
        const invoices = response.invoices.map(res => res);
        // {
        //   const balance = parseFloat(
        //     res.invoiceAmtDue.replace("$", "").replace(/,/g, "")
        //   );
        //   return {
        //     ...res,
        //     amountDue: balance,
        //   };
        // });
        return {
          invoices,
          totalInvoices: response.totalInvoiceCount,
        };
      })
    );
  }

  /**
   *
   * @param options
   * @param invoices
   */
  downloadInvoiceImages(
    options: "invoice" | "ticket" | "both",
    invoices: { invoiceNO: string }[]
  ): Observable<Blob> {
    const url = environment.baseDocURL + `invoicesImagesDoc`;
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.post(
          url,
          {
            customerNO: user.customerNO,
            customerHomeYard: user.custHomeYard,
            imageType: "pdf",
            options,
            invoices,
          },
          {
            responseType: "blob", // Specify 'blob' when expecting binary data.
          }
        );
      })
    );
  }

  /**
   *
   * @param imageType
   * @param invoices
   */
  downloadInvoiceList(
    imageType: "pdf" | "csv" | "txt",
    invoices: { invoiceNO: string }[]
  ): Observable<Blob> {
    const url = environment.baseDocURL + `invoicesListDoc`;
    return this.authService.getAuthenticatedUser$().pipe(
      switchMap(user => {
        if (!user) {
          return Promise.reject("unauthorized user");
        }
        return this.httpClient.post(
          url,
          {
            customerNO: user.customerNO,
            customerHomeYard: user.custHomeYard,
            imageType,
            options: "",
            invoices,
          },
          {
            responseType: "blob", // Specify 'blob' when expecting binary data.
          }
        );
      })
    );
  }
}
