import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { authGuard } from "./_guards/authenticated.guard";
import { signOutGuard } from "./_guards/sign-out.guard";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./_public/login/login.module").then(m => m.LoginModule),
  },
  {
    path: "logout",
    canActivate: [signOutGuard],
    loadChildren: () =>
      import("./_public/login/login.module").then(m => m.LoginModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./_public/forgot-password/forgot-password.module").then(
        m => m.ForgotPasswordModule
      ),
  },
  {
    path: "secure",
    canActivate: [authGuard],
    loadChildren: () =>
      import("./_secure/secure.module").then(m => m.SecureModule),
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "secure",
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "secure",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
